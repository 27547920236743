import React from 'react';
import ReactDOM from 'react-dom';

import CloseIcon from '../../assets/icons/close.svg';

import { st, classes } from './Toast.st.css';
import { PureComponentWithChildren } from '@wix/challenges-web-library';

export type ToastType = 'success' | 'error';

export interface IToastProps {
  opened?: boolean;
  type?: ToastType;
  isMobile: boolean;
  onClose(): void;
}

export interface IToastState {
  opened: boolean;
}

export class Toast extends PureComponentWithChildren<IToastProps, IToastState> {
  static defaultProps: Omit<IToastProps, 'onClose' | 'isMobile'> = {
    type: 'success',
  };

  state = {
    opened: false,
  };

  el = null;
  toastRoot = null;
  timer = null;

  componentDidMount() {
    this.el = document && document.createElement('div');
    this.toastRoot = document && document.getElementById('toast-root');
    this.toastRoot && this.toastRoot.appendChild(this.el);

    if (this.props.opened) {
      this.setState({ opened: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opened !== this.props.opened) {
      this.setState({ opened: this.props.opened });
    }

    if (this.state.opened) {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.handleClose, 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.toastRoot &&
      this.toastRoot.removeChild &&
      this.toastRoot.removeChild(this.el);
  }

  handleClose = () => {
    this.setState({ opened: false });
    this.props.onClose();
  };

  render() {
    const { isMobile } = this.props;

    const toastContent = (
      <section
        className={st(classes.root, {
          mobile: isMobile,
          type: this.props.type,
        })}
      >
        <p className={classes.content}>{this.props.children}</p>
        <button onClick={this.handleClose} className={classes.button}>
          <CloseIcon />
        </button>
      </section>
    );

    return (
      (this.state.opened && ReactDOM.createPortal(toastContent, this.el)) ||
      null
    );
  }
}
