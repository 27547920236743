import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

import {
  IChallengeSettings,
  TextAlignment,
  ImageShape,
  ImageRatio,
  CropSelection,
  ILayoutType,
  IImageLayoutType,
  IImageResizeOptions,
  CategoriesTabsTypeOptions,
  CategoriesTabsAlignment,
  CategoriesTabsMobileTypeOptions,
  ChallengeSettingsParams,
  ProgramCardsAlignment,
} from './Settings/challengeSettings/challengeSettings.types';

export const CategoriesTabsChosenCategoryDefault = 'all-tabs';

export const programListSettings = createSettingsParams<IChallengeSettings>({
  layoutType: {
    getDefaultValue() {
      return ILayoutType.Grid;
    },
  },
  imageLayoutType: {
    getDefaultValue() {
      return IImageLayoutType.Left;
    },
  },
  itemsPerRow: {
    getDefaultValue() {
      return 3;
    },
  },
  displayImage: {
    getDefaultValue() {
      return true;
    },
  },
  displayDuration: {
    getDefaultValue() {
      return true;
    },
  },
  displayParticipants: {
    getDefaultValue() {
      return true;
    },
  },
  displayDivider: {
    getDefaultValue() {
      return true;
    },
  },
  displayPrice: {
    getDefaultValue() {
      return true;
    },
  },
  [ChallengeSettingsParams.DisplayCategoriesTabs]: {
    getDefaultValue() {
      return true;
    },
  },
  [ChallengeSettingsParams.CategoriesTabsType]: {
    getDefaultValue() {
      return CategoriesTabsTypeOptions.Tabs;
    },
  },
  [ChallengeSettingsParams.CategoriesTabsMobileType]: {
    getDefaultValue() {
      return CategoriesTabsMobileTypeOptions.Dropdown;
    },
  },
  [ChallengeSettingsParams.CategoriesTabsFitToWidth]: {
    getDefaultValue() {
      return false;
    },
  },
  [ChallengeSettingsParams.CategoriesTabsAlignment]: {
    getDefaultValue() {
      return CategoriesTabsAlignment.Center;
    },
  },
  [ChallengeSettingsParams.CategoriesTabsMobileAlignment]: {
    getDefaultValue() {
      return CategoriesTabsAlignment.Center;
    },
  },
  [ChallengeSettingsParams.CategoriesTabsChosenCategory]: {
    getDefaultValue() {
      return CategoriesTabsChosenCategoryDefault;
    },
  },
  textAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  sideCardsVerticalSpacing: {
    getDefaultValue: () => 32,
  },
  cardSpacing: {
    getDefaultValue: () => 32,
  },
  imageResizeOptions: {
    getDefaultValue() {
      return IImageResizeOptions.Crop;
    },
  },
  imageShape: {
    getDefaultValue() {
      return ImageShape.Rectangle;
    },
  },
  imageRatio: {
    getDefaultValue() {
      return ImageRatio.HD;
    },
  },
  cropSelection: {
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  // for visitor. do not change the key for backward compatibility
  buttonText: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge-card.join-button');
    },
  },
  buttonTextForParticipant: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge-card.view-button');
    },
  },
  challengesVisible: {
    getDefaultValue() {
      return {};
    },
  },
  challengesOrdering: {
    getDefaultValue() {
      return { order: [] };
    },
  },
  challengeNameTag: {
    getDefaultValue() {
      return 'h2';
    },
  },
  labelParticipant: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.participant.joined');
    },
  },
  labelPendingRequest: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.participant.join-requested');
    },
  },
  labelChallengeCompleted: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('live-site.challenge-list.badge.completed');
    },
  },
  labelJoinRejected: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.participant.join-rejected');
    },
  },
  labelJoinApproved: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.participant.join-approved');
    },
  },
  dpListTitle: {
    getDefaultValue() {
      return false;
    },
  },
  listTitleTag: {
    getDefaultValue() {
      return 'h1';
    },
  },
  listTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('program-list.default-title');
    },
  },
  listTitleAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  [ChallengeSettingsParams.ProgramCardsAlignment]: {
    getDefaultValue() {
      return ProgramCardsAlignment.Left;
    },
  },
  categoriesOrder: {
    getDefaultValue() {
      return {};
    },
  },
  categoriesHidden: {
    getDefaultValue() {
      return {};
    },
  },
});

export default programListSettings;
