import {
  Trigger,
  AccessType,
} from '@wix/ambassador-challenges-v1-challenge/types';

export const ProgramRewardTrigger = {
  ...Trigger,
} as const;

export const ProgramAccessType = {
  ...AccessType,
} as const;

export type ProgramRewardTriggerType =
  (typeof ProgramRewardTrigger)[keyof typeof ProgramRewardTrigger];
