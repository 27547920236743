import { IUserType } from '../UserContext';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';

export const userTypeHandlers = {
  isTouchedChallenge: (userType: IUserType) => {
    // User already did one of actions according to join the challenge.

    return [
      ParticipantState.FAILED,
      ParticipantState.REMOVED,
      ParticipantState.JOIN_REJECTED,
      ParticipantState.COMPLETED,
      ParticipantState.RUNNING,
      ParticipantState.JOINED,
      ParticipantState.INVITED,
      ParticipantState.JOIN_REQUESTED,
      ParticipantState.LEFT,
      ParticipantState.PAYMENT_REQUESTED,
      ParticipantState.PAYMENT_STARTED,
      ParticipantState.SUSPENDED,
    ].includes(userType as ParticipantState);
  },
  isInIntermediateState: (userType: IUserType) => {
    // User already started the join process, but isn't joined yet.

    return [
      // ParticipantState.INVITED, todo: should we save it for this group?
      // ParticipantState.SUSPENDED,
      ParticipantState.PAYMENT_REQUESTED,
      ParticipantState.PAYMENT_STARTED,
    ].includes(userType as ParticipantState);
  },
  isInPaymentState: (userType: IUserType) => {
    // User is in the payment state.

    return [
      ParticipantState.PAYMENT_REQUESTED,
      ParticipantState.PAYMENT_STARTED,
    ].includes(userType as ParticipantState);
  },
  isJoinedAlready: (userType: IUserType) => {
    // User successfully joined the challenge and maybe even completed it already.

    return [
      ParticipantState.COMPLETED,
      ParticipantState.RUNNING,
      ParticipantState.JOINED,
      ParticipantState.FAILED,
      ParticipantState.SUSPENDED,
    ].includes(userType as ParticipantState);
  },
  isJoinedAlreadyWithoutSuspended: (userType: IUserType) => {
    return (
      userTypeHandlers.isJoinedAlready(userType) &&
      userType !== ParticipantState.SUSPENDED
    );
  },
  isUserLeftOrRejected: (userType: IUserType) => {
    // User tried to join or complete the challenge, but got rejected.

    return [
      ParticipantState.REMOVED,
      ParticipantState.JOIN_REJECTED,
      ParticipantState.LEFT,
    ].includes(userType as ParticipantState);
  },
  isCompleted: (userType: IUserType) => {
    // User completed the challenge

    return [ParticipantState.COMPLETED, ParticipantState.FAILED].includes(
      userType as ParticipantState,
    );
  },
};

export default userTypeHandlers;
