import { useControllerData } from '../main/controllerContext';
import React from 'react';

export const useMemberPaidPlans = () => {
  const props = useControllerData();
  return {
    eligiblePlans: props.eligiblePlans,
    userPaidPlans: props.userPaidPlans,
  };
};

export const withPaidPlans = <Props = any,>(
  Component: React.ComponentType<Props>,
) => {
  return (props: Props) => {
    const value = useMemberPaidPlans();
    return <Component {...props} {...value} />;
  };
};
