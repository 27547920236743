import programListSettings, {
  CategoriesTabsChosenCategoryDefault,
} from './settingsParams';

export function getProgramOrderKey(chosenCategory: string) {
  return chosenCategory === CategoriesTabsChosenCategoryDefault
    ? 'order'
    : `c${chosenCategory}`;
}

export function getProgramsOrder(opts: {
  settings: {
    get(key: (typeof programListSettings)['challengesOrdering']): any;
  };
  chosenCategory: string;
}) {
  const orderSettings = opts.settings.get(
    programListSettings.challengesOrdering,
  );

  if (orderSettings?.[getProgramOrderKey(opts.chosenCategory)]?.length) {
    return orderSettings[getProgramOrderKey(opts.chosenCategory)];
  }

  return orderSettings?.order || [];
}
