import debounce from 'lodash/debounce';
import type { IWixStatic } from '@wix/native-components-infra/dist/src/types/wix-sdk';

import { VIEW_MODE } from '../config/constants';

const MIN_WIDTH_MAP = {
  mobile: 280,
};

function resizeConstraint(Wix: IWixStatic, minWidth: number) {
  return (event) => {
    const viewMode = Wix.Utils.getViewMode();
    const newWidth = event.target.innerWidth;

    if (
      viewMode === VIEW_MODE.Editor.toLocaleLowerCase() &&
      newWidth < minWidth
    ) {
      Wix.resizeComponent({
        width: minWidth,
        height: event.target.innerHeight,
      });
    }
  };
}

export function setWidthRestrictions(Wix: IWixStatic) {
  if (!Wix) {
    return;
  }

  const deviceType = Wix.Utils.getDeviceType();
  const minWidth = MIN_WIDTH_MAP[deviceType];
  const debouncedResize = debounce(resizeConstraint(Wix, minWidth), 300);

  window.addEventListener('resize', debouncedResize);

  return () => {
    window.removeEventListener('resize', debouncedResize);
  };
}
