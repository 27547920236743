import React from 'react';
import { FullscreenContext, IFullscreenContext } from './FullscreenContext';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IFullscreenProviderProps extends IFullscreenContext {}

async function requestFullscreen(element: HTMLElement) {
  const rFS =
    element.requestFullscreen ||
    // @ts-expect-error
    element.webkitRequestFullscreen ||
    // @ts-expect-error
    element.mozRequestFullScreen ||
    // @ts-expect-error
    element.msRequestFullscreen;

  return rFS.call(element);
}

async function exitFullscreen() {
  // @ts-expect-error
  const exitFs = document.exitFullscreen || document.webkitExitFullscreen;

  return exitFs.call(document);
}

export function isFullscreenMode() {
  if (typeof document === 'undefined') {
    return false;
  }

  return (
    // @ts-expect-error
    !!document.fullscreenElement || !!document.webkitCurrentFullScreenElement
  );
}

const toggleFullscreenMode = async (element: HTMLElement) => {
  if (isFullscreenMode()) {
    element.style.overflow = 'unset';

    await exitFullscreen();
  } else if (element) {
    element.style.overflow = 'auto';

    await requestFullscreen(element);
  }
};
// todo use only on participant page
export const FullscreenProvider: FCWithChildren<IFullscreenProviderProps> = (
  props,
) => {
  return (
    <FullscreenContext.Provider
      value={{
        toggleFullscreen: async (element) => {
          await toggleFullscreenMode(element);
        },
      }}
    >
      {props.children}
    </FullscreenContext.Provider>
  );
};

FullscreenProvider.displayName = 'Fullscreen Provider';
