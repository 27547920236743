import React, { useEffect } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const useIsFullWithInViewer = () => {
  const { dimensions, isEditorX } = useEnvironment();
  const [isFullWidth, setIsFullWidth] = React.useState<boolean>(false);

  useEffect(() => {
    if (isEditorX) {
      // we decided that in editor X full width is always equal false
      // https://wix.slack.com/archives/CJ4N4DYT1/p1668173869300859
      setIsFullWidth(false);
      return;
    }
    setIsFullWidth(
      String(dimensions.width) === '100%' || String(dimensions.width) === '',
    );
  }, [dimensions]);

  return isFullWidth;
};
