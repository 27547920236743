import userTypeHandlers from '../../../contexts/User/helpers/userTypeHandlers';
import { MemberChallenge } from '@wix/ambassador-challenges-v1-challenge/types';

export const filterChallenges = (
  challenges: MemberChallenge[] = [],
  challengesVisible: any,
): MemberChallenge[] => {
  return challenges.filter((challenge) => {
    const participantState = challenge?.summary?.participation?.state;
    const isFinished =
      challenge.challenge?.transitions?.[0]?.state === 'FINISHED';
    const isParticipant = userTypeHandlers.isJoinedAlready(
      participantState as any,
    );

    const currChallengesFromSettings =
      challengesVisible[challenge.challenge.id] ||
      challengesVisible[challenge.challenge.id.substr(0, 5)] ||
      challengesVisible[challenge.challenge.settings.description.title];

    const isChallengeVisible =
      !challengesVisible || typeof currChallengesFromSettings === 'undefined'
        ? true
        : !currChallengesFromSettings?.hidden;

    return (!isFinished || isParticipant) && isChallengeVisible;
  });
};
