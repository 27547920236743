export interface ILoadingContext extends ILoadingProps {}

export enum ILoadingStatus {
  'DEFAULT' = 'DEFAULT',
  'OK' = 'OK',
  'NOT_ELIGIBLE' = 'NOT_ELIGIBLE',
  'ERROR' = 'ERROR',
}

export interface ILoadingProps {
  isLoading: boolean;
  loadingStatus: ILoadingStatus;
  showLoader(): void;
  hideLoader(loadingStatus?: ILoadingStatus): void;
}
