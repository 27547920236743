import { SettingsParamType } from '@wix/tpa-settings';

export enum ILayoutType {
  Grid = 'Grid',
  Side = 'Side',
}

export enum IImageLayoutType {
  Left = 'Left',
  Discordantly = 'Discordantly',
  Right = 'Right',
}

export enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum IImageResizeOptions {
  Crop = 'Crop',
  Fit = 'Fit',
}

export enum ImageShape {
  Square = 'square',
  Round = 'round',
  Rectangle = 'rectangle',
}

export enum ImageRatio {
  HD = 'hd',
  Standard = 'standard',
}
export enum CropSelection {
  TopLeft = 'TOP_LEFT',
  top = 'TOP',
  TopRight = 'TOP_RIGHT',
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  Bottom = 'BOTTOM',
  BottomRight = 'BOTTOM_RIGHT',
}

type TextTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

export enum CategoriesTabsTypeOptions {
  Tabs = 'tabs',
}

export enum CategoriesTabsMobileTypeOptions {
  Tabs = 'tabs',
  Dropdown = 'Dropdown',
}

export enum CategoriesTabsAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum ProgramCardsAlignment {
  Left = 'left',
  Center = 'center', // leave it for the future. you never know
  Right = 'right',
}

export enum ChallengeSettingsParams {
  DisplayCategoriesTabs,
  CategoriesTabsType,
  CategoriesTabsMobileType,
  CategoriesTabsFitToWidth,
  CategoriesTabsAlignment,
  CategoriesTabsMobileAlignment,
  CategoriesTabsChosenCategory,
  ProgramCardsAlignment,
}

export interface IChallengeSettings {
  layoutType: ILayoutType;
  imageLayoutType: IImageLayoutType;
  itemsPerRow: number;
  displayImage: boolean;
  displayDuration: boolean;
  displayParticipants: boolean;
  displayDivider: boolean;
  displayPrice: boolean;
  [ChallengeSettingsParams.DisplayCategoriesTabs]: boolean;
  [ChallengeSettingsParams.CategoriesTabsType]: CategoriesTabsTypeOptions;
  [ChallengeSettingsParams.CategoriesTabsMobileType]: CategoriesTabsMobileTypeOptions;
  [ChallengeSettingsParams.CategoriesTabsFitToWidth]: boolean;
  [ChallengeSettingsParams.CategoriesTabsAlignment]: CategoriesTabsAlignment;
  [ChallengeSettingsParams.CategoriesTabsMobileAlignment]: CategoriesTabsAlignment;
  [ChallengeSettingsParams.CategoriesTabsChosenCategory]: string;
  textAlignment: TextAlignment;
  sideCardsVerticalSpacing: number;
  cardSpacing: number;
  imageResizeOptions: IImageResizeOptions;
  imageShape: ImageShape;
  imageRatio: ImageRatio;
  cropSelection: CropSelection;
  buttonText: SettingsParamType.Text;
  buttonTextForParticipant: SettingsParamType.Text;
  challengesVisible: {};
  challengesOrdering: { order: [] };
  challengeNameTag: TextTag;
  labelParticipant: SettingsParamType.Text;
  labelPendingRequest: SettingsParamType.Text;
  labelChallengeCompleted: SettingsParamType.Text;
  labelJoinRejected: SettingsParamType.Text;
  labelJoinApproved: SettingsParamType.Text;
  dpListTitle: boolean;
  listTitleAlignment: TextAlignment;
  [ChallengeSettingsParams.ProgramCardsAlignment]: ProgramCardsAlignment;
  listTitleTag: TextTag;
  listTitleText: SettingsParamType.Text;
  categoriesHidden: {};
  categoriesOrder: {};
}
