import { MemberChallenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { getProgramsOrder } from '../getProgramsOrderSettingsKey';

export const orderChallenges = (
  challenges: MemberChallenge[] = [],
  settings: any,
  chosenCategory: string,
): MemberChallenge[] => {
  const orderFromSettings = getProgramsOrder({
    settings,
    chosenCategory,
  });

  return orderFromSettings.length
    ? [
        ...orderFromSettings
          .map((orderedChall: { id: string }) => {
            const ch = challenges.find((_ch) => {
              return (
                _ch?.challenge?.id.substr(0, 5) ===
                orderedChall?.id?.substr(0, 5)
              );
            });

            return ch || null;
          })
          .filter((item) => !!item),
        ...challenges.filter((ch) => {
          const isInOrder = orderFromSettings.find(
            (i: { id: string }) =>
              i.id.substr(0, 5) === ch?.challenge?.id.substr(0, 5),
          );
          return !isInOrder;
        }),
      ]
    : challenges;
};
